<template>
  <v-container fluid class="pa-0 ma-0">

    <!-- 상단 툴바 -->
    <v-toolbar dense flat>
      <v-btn text small @click="goList">
        <v-icon small>mdi-view-list</v-icon>
        목록
      </v-btn>
      <v-btn
        :disabled="isApproverError"
        text
        small
        color="primary"
        @click="sendApproval">
        <v-icon small>mdi-file-move</v-icon>
        상신
      </v-btn>
      <v-btn
        :disabled="isApproverError"
        text
        small
        color="success"
        @click="setImsiSave">
        <v-icon small>mdi-file-document-edit</v-icon>
        임시저장
      </v-btn>
      <v-btn
        text
        small
        color="error"
        @click="cancel">
        <v-icon small>mdi-trash-can-outline</v-icon>
        {{ form.id ? '삭제' : '취소' }}
      </v-btn>

      <v-spacer></v-spacer>

      <v-chip
        label
        :color="currStatusColor"
        text-color="white"
        class="ma-2"
      >
        <v-icon small class="mr-1">{{ currStatusIcon }}</v-icon>
        {{ form.gubun1 }}
      </v-chip>
    </v-toolbar>

    <v-divider></v-divider>

    <!-- 컨텐츠 영역 -->
    <v-card
      tile
      :elevation="0"
      class="ma-0 pa-0"
    >
      <v-row
        no-gutters
      >
        <v-col v-if="!isId" cols="12">
          <v-alert
            prominent
            type="warning"
            class="ma-4 pa-10"
          >존재하지 않는 데이터 입니다</v-alert>
        </v-col>
        <v-col v-else cols="12" class="ma-0 pa-0">
          <v-row
            no-gutters
          >
            <v-col cols="12" class="ma-0 pa-0">
              <v-row no-gutters class="ma-0 pa-0">
                <v-col cols="12" md="7" xs="12" sm="12" class="ma-0 pa-0">
                  <v-row
                    no-gutters
                    align="center"
                    justify="start"
                  >
                    <v-btn
                      icon
                      @click.native="form.isStar = !form.isStar"
                      class="ml-2"
                    >
                      <v-icon
                        v-if="!form.isStar"
                        color="grey lighten-1"
                      >mdi-star-outline</v-icon>
                      <v-icon
                        v-else
                        color="yellow"
                      >mdi-star</v-icon>
                    </v-btn>
                    <v-text-field
                      filled
                      rounded
                      dense
                      ref="txtSubject"
                      v-model.trim="form.subject"
                      v-validate="'required|max:30'"
                      :error-messages="errors.collect('subject')"
                      data-vv-name="subject"
                      required
                      placeholder="*제목을 입력하세요(필수)"
                      :maxlength="subjectLimit"
                      style="max-height: 60px;"
                      class="ml-1 mr-4 mt-4 mb-0 pa-0"
                    ></v-text-field>
                  </v-row>
                </v-col>
                <v-col cols="12" md="5" xs="12" sm="12" class="ma-0 pa-0">
                  <v-row
                    no-gutters
                    align="center"
                    justify="start"
                  >
                    <v-text-field
                      filled
                      rounded
                      dense
                      ref="txtCode"
                      v-model="form.code"
                      maxlength="30"
                      placeholder="내부결재번호"
                      :error="isOverlabed"
                      style="max-width: 300px;max-height: 60px;"
                      class="ml-2 mt-4 mb-0 pa-0"
                    ></v-text-field>
                    <v-btn small text class="mb-0"
                      :color="mustChk ? 'error' : 'indigo'"
                      @click="chkEaCodeOverlap"
                    >
                      <v-icon small class="text-left">
                        {{ chkMulti ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                      </v-icon>
                      중복체크
                    </v-btn>
                    <v-spacer></v-spacer>
                    <!-- !! 히스토리 뷰 팝업 -->
                    <v-btn
                      v-show="form.depth > 1"
                      text
                      small
                      color="primary"
                      @click="viewHistoryPop"
                    >
                      <v-icon small>mdi-history</v-icon>
                      history
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-col>

            <!-- 좌측 컨텐츠 시작 --->
            <v-col cols="12" md="7" xs="12" sm="12">
              <v-container fluid class="ma-0 pa-0">
                <v-row
                  align="center"
                  justify="center"
                  no-gutters
                >
                  <!-- !! 연계정보 -->
                  <v-col cols="12" class="pt-3 pb-0 px-2">
                    <span class="text-subtitle-2 grey--text text--darken-1 mr-3">연계정보</span>
                  </v-col>
                  <v-col cols="12" class="pt-1 pb-1 px-1">
                    <v-alert
                      v-show="form.rId && form.rType"
                      text
                      color="indigo"
                      dense
                      class="text-body-2 ma-0 px-2 pt-2 pb-1"
                      :class="isDelRelation ? 'grey--text text--darken-1' : ''"
                    >
                      <v-icon
                        small
                        :color="isDelRelation ? 'grey darken-1' : 'indigo'"
                        class="mx-1 mb-1"
                      >mdi-link-variant</v-icon>
                      {{ isDelRelation ? '[삭제됨] ' : '' }}{{ relData }}
                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            small
                            :color="isDelRelation ? 'grey darken-1' : 'primary lighten-1'"
                            @click="gotoDetail"
                            class="ml-1"
                          >mdi-launch</v-icon>
                        </template>
                        <span>바로가기</span>
                      </v-tooltip>
                    </v-alert>
                  </v-col>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>

                  <!-- !! 첨부파일 + 공유링크: form.id 가 있어야 등록가능하다 -->
                  <v-col cols="12" class="pt-3 pb-0 px-2">
                    <span class="text-subtitle-2 grey--text text--darken-1">첨부파일 + 공유링크</span>
                    <!-- @: 첨부파일 추가 아이콘 -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-show="form.id"
                          v-on="on"
                          small
                          :color="mdiListEditButtonColor"
                          class="ml-3"
                          @click="showFilePop"
                        >mdi-paperclip</v-icon>
                      </template>
                      <span>첨부파일등록</span>
                    </v-tooltip>
                    <!-- @: 공유링크 추가 아이콘 -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-show="form.id"
                          v-on="on"
                          small
                          :color="mdiListEditButtonColor"
                          class="ml-3"
                          @click="showShareLinkPop"
                        >mdi-link-plus</v-icon>
                      </template>
                      <span>공유링크등록</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12" class="py-2 px-2">
                    <!-- 첨부파일 목록 -->
                    <div
                      v-for="file in attachedFiles"
                      :key="file.id"
                      class="my-1 text-body-2"
                    >
                      <v-hover v-slot:default="{ hover }" transition="fade-transition">
                        <div class="ma-0 pa-0">
                          <div>
                            <v-icon
                              :color="file.isAnotherWrite ? 'purple' : ''"
                              x-small
                            >
                              {{ file.isAnotherWrite ? 'mdi-ray-start-arrow' : 'mdi-subdirectory-arrow-right' }}
                            </v-icon>
                            <v-icon v-show="file.type === 36" small :color="mdiDownloadButtonColor">mdi-paperclip</v-icon>
                            <v-icon v-show="file.type === 38" small :color="mdiLinkShareIconColor">mdi-link-plus</v-icon>
                            <!-- !! 첨부파일, 공유링크 카테고리 태그(attach file category tag) 컴포넌트 -->
                            <attach-file-category-tag
                              v-show="file.type === 36 || file.type === 38"
                              :caption="file.gubun2"
                            ></attach-file-category-tag>
                            {{ file.str1 }}
                            <span class="ml-1 text-caption grey--text">
                              ({{ strDateFormat2(file.createdAt) }} {{ file.wname }})
                            </span>
                            <!-- 사용안함:[2021.9.1] 첨부파일 다운로드 -->
                            <!-- <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  v-show="hover && file.type === 36"
                                  v-on="on"
                                  small
                                  right
                                  :color="mdiDownloadButtonColor"
                                  class="ml-3"
                                  @click="fileDownload(file)"
                                >mdi-cloud-download-outline</v-icon>
                              </template>
                              <span>다운로드</span>
                            </v-tooltip> -->
                            <!-- 첨부파일 바로보기 -->
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  v-show="hover && file.type === 36"
                                  v-on="on"
                                  small
                                  right
                                  :color="mdiOpenFileButtonColor"
                                  class="ml-3"
                                  @click="fileDirectView(file)"
                                >mdi-eye-outline</v-icon>
                              </template>
                              <span>바로보기</span>
                            </v-tooltip>
                            <!-- 첨부파일 삭제 -->
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  v-show="hover && file.type === 36"
                                  v-on="on"
                                  small
                                  right
                                  :color="mdiTrashCanOutlineButtonColor"
                                  class="ml-3"
                                  @click="deleteFile(file.id)"
                                >mdi-trash-can-outline</v-icon>
                              </template>
                              <span>삭제</span>
                            </v-tooltip>
                            <!-- 공유링크 바로가기 -->
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  v-show="hover && file.type === 38"
                                  v-on="on"
                                  small
                                  right
                                  :color="mdiPlusBoxOutlineButtonColor"
                                  class="ml-3"
                                  @click="shareLinkDirectGo(file)"
                                >mdi-launch</v-icon>
                              </template>
                              <span>바로가기</span>
                            </v-tooltip>
                            <!-- 공유링크 삭제 -->
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  v-show="hover && file.type === 38"
                                  v-on="on"
                                  small
                                  right
                                  :color="mdiTrashCanOutlineButtonColor"
                                  class="ml-3"
                                  @click="deleteShareLink(file.id)"
                                >mdi-trash-can-outline</v-icon>
                              </template>
                              <span>삭제</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </v-hover>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>

                  <v-col cols="12" class="pt-3 pb-0 px-2">
                    <span class="text-subtitle-2 grey--text text--darken-1">내용</span>
                  </v-col>
                  <v-col cols="12" class="py-2 px-2">
                    <!-- vue2editor -->
                    <vue-editor
                      v-model="form.content"
                      :editor-toolbar="customToolbar"
                      class="mb-8"
                      style="height: 400px"
                    ></vue-editor>
                  </v-col>
                  <v-col cols="12" class="mb-8">
                  </v-col>

                </v-row>
              </v-container>
            </v-col>

            <v-divider vertical class="ma-0 pa-0"></v-divider>

            <!-- 우측 컨텐츠 시작 --->
            <v-col>
              <v-container fluid class="ma-0 pa-0">
                <v-row
                  align="center"
                  justify="center"
                  no-gutters
                >
                  <!-- !! 결재자 -->
                  <v-col cols="12" class="pt-3 pb-0 px-2">
                    <span class="text-subtitle-2 grey--text text--darken-1">결재자</span>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-show="form.id"
                          v-on="on"
                          small
                          :color="mdiPlusBoxOutlineButtonColor"
                          class="ml-3"
                          @click="setEalinePopup(1)"
                        >mdi-plus-box-outline</v-icon>
                      </template>
                      <span>등록</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-4 px-2">
                    <template v-for="(approver, i) in form.approvers">
                      <v-row
                        :key="i"
                        no-gutters
                      >
                        <v-col cols="12" xs="12" sm="12">
                          <v-card
                            elevation="0"
                            class="ma-0 pa-0"
                          >
                            <v-avatar tile size="20" color="blue">
                              <span class="white--text text-body-2">{{ i + 1 }}</span>
                            </v-avatar>
                            <v-list dense class="ma-0 pa-0">
                              <v-list-item
                                v-for="item in approver.items"
                                :key="item.email"
                                class="ma-0 py-0"
                              >
                                <!-- 사용안함: 유저 추가후 사진이 보이지 않는 현상때문에 포기 -->
                                <!-- <v-avatar
                                  v-show="item.picture"
                                  size="36px" class="mr-2"
                                >
                                  <img :src="item.picture">
                                </v-avatar> -->
                                <span class="text-body-2" :class="item.result === 4 ? 'grey--text text--lighten-1 text-decoration-line-through' : ''">
                                  {{ item.name }}
                                  <span class="text-caption grey--text text--darken-1">
                                    {{ item.rank }}
                                  </span>
                                </span>
                                <v-chip
                                  v-show="item.result === 4"
                                  x-small label class="mx-1 px-2 text-caption"
                                  color="error"
                                >
                                  공유해제
                                </v-chip>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                  <!-- !! 회람자 -->
                  <v-col cols="12" class="pt-3 pb-0 px-2">
                    <span class="text-subtitle-2 grey--text text--darken-1">회람자</span>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-show="form.id"
                          v-on="on"
                          small
                          :color="mdiPlusBoxOutlineButtonColor"
                          class="ml-3"
                          @click="setEalinePopup(2)"
                        >mdi-plus-box-outline</v-icon>
                      </template>
                      <span>등록</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-4 px-2">
                    <!-- 회람자 목록 -->
                    <v-list dense class="ma-0 pa-0">
                      <v-list-item
                        v-for="(viewer, i) in form.viewers"
                        :key="i"
                        class="ma-0 py-0"
                      >
                        <!-- 사용안함: 유저 추가후 사진이 보이지 않는 현상때문에 포기 -->
                        <!-- <v-avatar
                          v-show="viewer.picture"
                          size="36px" class="mr-2"
                        >
                          <img :src="viewer.picture">
                        </v-avatar> -->
                        <span class="text-body-2">
                          {{ viewer.name }}
                          <span class="text-caption grey--text text--darken-1">
                            {{ viewer.rank }}
                          </span>
                        </span>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                  <!-- !! 의견(댓글) -->
                  <v-col cols="12" class="pt-3 pb-0 px-2">
                    <span class="text-subtitle-2 grey--text text--darken-1">의견</span>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-show="form.id"
                          v-on="on"
                          small
                          :color="mdiPlusBoxOutlineButtonColor"
                          class="ml-3"
                          @click="showCommentPop"
                        >mdi-comment-text-outline</v-icon>
                      </template>
                      <span>등록</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-4 px-2">
                    <!-- 의견 목록 -->
                    <div
                      v-for="(comment, i) in comments"
                      :key="i"
                      class="my-1 text-body-2"
                    >
                      <v-hover v-slot:default="{ hover }" transition="fade-transition">
                        <div class="ma-0 pa-0">
                          <div>
                            <v-icon
                              :color="comment.isAnotherWrite ? 'purple' : ''"
                              x-small
                            >
                              {{ comment.isAnotherWrite ? 'mdi-ray-start-arrow' : 'mdi-subdirectory-arrow-right' }}
                            </v-icon>
                            {{ comment.str1 }}
                            <span class="text-caption grey--text">({{ strDateFormat2(comment.createdAt) }} {{ comment.wname }})</span>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  v-show="hover"
                                  v-on="on"
                                  small
                                  right
                                  :color="mdiTrashCanOutlineButtonColor"
                                  class="ml-3"
                                  @click="deleteComment(comment.id)"
                                >mdi-trash-can-outline</v-icon>
                              </template>
                              <span>삭제</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </v-hover>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <!-- 첨부파일 다운로드시 오버레이 -->
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- 결재라인 세팅 팝업 -->
    <set-ealine-dialog ref="setEalineDialog"></set-ealine-dialog>
    <!-- 댓글 팝업 -->
    <add-comment-pop ref="addCommentPop"></add-comment-pop>
    <!-- 첨부파일 팝업 -->
    <add-file-pop ref="addFilePop"></add-file-pop>
    <!-- 공유링크 팝업 컴포넌트 -->
    <add-share-link-pop ref="addShareLinkPop"></add-share-link-pop>
    <!-- 히스토리 팝업 -->
    <view-history-pop ref="viewHistoryPop"></view-history-pop>

  </v-container>
</template>

<script>
// @: utils
import sleep from '@/lib/sleep'
import ko from 'vee-validate/dist/locale/ko'

// @: filters
import isNumber from '@/filters/isNumber'
import isSpKey from '@/filters/isSpecialCharacter'
import strDateFormat2 from '@/filters/strDateFormat2'

// @: vue2editor
import { VueEditor } from 'vue2-editor'

// @: popups
import setEalineDialog from '@/components/eawriting/setEalineDialog' // 결재라인 세팅팝업
import addCommentPop from '@/components/eawriting/addCommentPop' // 댓글 팝업
import addFilePop from '@/components/eawriting/addFilePop' // 첨부파일 팝업
import addShareLinkPop from '@/components/eawriting/addShareLinkPop' // 공유링크 팝업
import viewHistoryPop from '@/components/eawriting/viewHistoryPop'

// @: tag components
import attachFileCategoryTag from '@/components/tags/attachFileCategoryTag' // 첨부파일,공유링크 카테고리 태그

// !! 현재 dir
const currDir = 'eawriting'

export default {
  components: {
    setEalineDialog,
    VueEditor,
    addCommentPop,
    addFilePop,
    addShareLinkPop,
    viewHistoryPop,
    attachFileCategoryTag
  },

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 아이콘 컬러
    mdiPlusBoxOutlineButtonColor: 'info darken-1',
    mdiListEditButtonColor: 'indigo accent-2',
    mdiTrashCanOutlineButtonColor: 'warning darken-1',
    mdiUploadButtonColor: 'primary lighten-1',
    mdiDownloadButtonColor: 'success lighten-1',
    mdiLinkShareIconColor: 'cyan darken-2',
    mdiOpenFileButtonColor: 'orange lighten-1',
    // 구분: 현재 상태에 따른 색상 변화
    currStatusColor: 'grey darken-1',
    statusColors: {
      1: 'brown darken-2', // 임시저장
      2: 'primary', // 결재중
      3: 'success', // 최종승인
      4: 'error', // 불허
      5: 'warning', // 반려
      6: 'error' // 오류
    },
    // 구분: 현재 상태 아이콘
    currStatusIcon: 'mdi-content-save-cog',
    statusIcon: {
      1: 'mdi-content-save-cog', // 임시저장
      2: 'mdi-fast-forward-outline', // 결재중
      3: 'mdi-check-underline-circle', // 최종승인
      4: 'mdi-cancel', // 불허
      5: 'mdi-backup-restore', // 반려
      6: 'mdi-alert-circle' // 오류
    },
    // 구분: 등록/수정용 form
    form: {
      id: 0,
      depth: 1, // 횟수
      tern: 1, // 결재라인순서
      status: 1, // 상태(아이디가 있으면 임시저장, 없으면 작성중)
      gubun1: '작성중', // 상태문자열
      subject: '', // 제목
      code: '', // 내부결재번호(코드)
      content: null, // 내용
      rId: 0, // 연계정보 아이디
      rType: 0, // 연계정보 타입
      rInfo: '', // 연계정보 표시
      rTid: 0, // 연계정보 팀아이디
      isStar: 0, // 중요표시별
      approvers: [], // 결재자 배열
      viewers: [], // 회람자 배열
      isApproverChanged: false, // 결재자 변동여부
      isViewerChanged: false // 회람자 변동여부
    },
    //
    articleId: 0,
    // 구분: 제목 글자수 제한
    subjectLimit: 30,
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        subject: '제목'
      }
    },
    // 구분: 에디터 용
    customToolbar: [
      [ { 'header': [ false, 1, 2, 3, 4, 5, 6 ] } ],
      [ 'bold', 'italic', 'underline', 'strike' ],
      [ { 'header': 1 }, { 'header': 2 } ],
      [ { 'list': 'ordered' }, { 'list': 'bullet' } ],
      [ { 'color': [] }, { 'background': [] } ],
      [ 'link' ]
    ],
    // 구분: 내부결재번호 중복체크용 변수들
    mustChk: false, // 중복체크를 해야 하는지
    chkMulti: false, // 참고: 중복 확인했는지 여부
    isOverlabed: false, // 참고: 중복 되었는지 여부
    // 구분: 결재자/회람자 배열
    // approvers: [], // 결재자 배열
    // viewers: [], // 회람자 배열
    // 구분: 등록 오류 메시지
    regErrMsg: '[등록 오류] 먼저 제목을 등록하고 임시저장하시기 바랍니다.',
    // 구분: 임시저장 여부 플래그. 최초 등록시 1번만 실행하기 위해 사용되며 이후엔 쓸일이 없다
    isTempSave: false,
    // 구분: 댓글 목록
    comments: [],
    // 구분: 첨부파일 목록(첨부파일 + 공유링크)
    attachedFiles: [],
    // 구분: 첨부파일 다운로드 로딩시 오버레이
    overlay: false,
    // 구분: 결재자,회람자 변동 비교를 위한 변수
    oldApprovers: '',
    oldViewers: '',
    // 구분: 결재자 중에 오류인 넘이 하나라도 있으면 true
    isApproverError: false,
    // 구분: 로그인 유저가 속한 팀아이의 배열
    myTids: [],
    // 구분: 존재하는 아이디 인지 여부
    isId: true,
    // 구분: 2021.2.22 연계데이터가 있고 연계데이터가 삭제된경우 true
    isDelRelation: false,
    // 구분:[2021.7.15] 연계데이터 정보
    relData: ''
  }),

  watch: {
    // !!중요: /eawriting 내부에서 라우트 변화를 감지하여 동적으로 변경해준다.
    '$route' (to, from) {
      const paramIds = to.params.id.split('-')
      if (paramIds.length > 1 && paramIds[1] === 'E') {
        // !! 임시저장이시엔 '-E' 가 붙어서 넘어온다
        this.articleId = parseInt(paramIds[0], 10)
        // 다시 해당 아이디로 리다이렉트 시킨다.
        this.$router.push(`/${currDir}/${this.articleId}`)
      } else {
        // !! 수정이 아닌 경우
        if (to.params.id === 'new') {
          this.init() // !! 새로작성인 경우 초기화 함수 호출
        } else {
          this.getArticle(to.params.id) // 임시저장으로 아이디가 넘어온 경우
        }
      }
    },
    'form.subject': { // !! 제목 감시
      handler: function (val, oldVal) {
        // !! 새로작성(id가 저장되지 않았다) && 입력한 제목이 1글자 이상인 경우
        if (this.form.id === 0 && val.length > 0 && !this.isTempSave) {
          // console.log(val)
          this.isTempSave = true // 1번만 실행하기 위해
          // 딜레이 후 임시저장 함수를 호출한다.
          sleep(1000).then(() => {
            this.setTempSave()
          })
        }

        // 글자수 제한을 넘지 못하게 깔끔하게 막는 방법
        // !! 제목 길이가 30 이라 넘으면 setTempSave() 로 보낼때 제목을 날려버린다
        if (val.length > this.subjectLimit) {
          this.form.subject = oldVal
          this.$refs.txtSubject.lazyValue = oldVal
        }
      }
    },
    'form.code': { // !! 내부결재번호 자동감지 & 중복체크
      handler: function (val, oldVal) {
        if (val.length > 0) { // 값이 있으면
          if (this.form.subject.length === 0 || this.form.id === 0) {
            // !! 임시저장되지 않은 상태에서 관리번호 못쓰게 막는다.
            this.form.code = oldVal
            this.$refs.txtCode.lazyValue = oldVal
            this.sbpop(new Error('제목을 먼저 입력하셔야 합니다.'))
          }

          if (isSpKey(val)) { // 특수문자 필터링
            this.form.code = oldVal
            this.$refs.txtCode.lazyValue = oldVal
          }
          this.mustChk = true // 중복체크 해야 함
          this.chkMulti = false // 중복확인을 안했다
          this.isOverlabed = false // 중복 아니다.
        } else { // 내용이 아예 없으면 중복 체크하지 않아도 됨
          this.mustChk = false // 중복체크 안해도 됨
          this.chkMulti = false // 중복확인을 안했다
          this.isOverlabed = false // 중복 아니다.
        }
      }
    }
  },

  computed: {
  },

  mounted () {
    this.$validator.localize('ko', this.dictionary)

    // console.log(this.$route.params)
    // this.$route.params.id 가 new 면 새로 작성임
    //  > 완전히 초기화 하고 상태는 '작성중' 으로 해야함
    // 숫자값이 넘어오면 DB 조회해야함.
    // console.log(this.$route.params.id)
    // const paramIds = this.$route.params.id
    // if (paramIds) { // 파라미터가 넘어온 경우
    //   if (paramIds === 'new') {
    //     this.init() // !! 새로작성인 경우 초기화 함수 호출
    //   } else {
    //     this.getArticle(paramIds) // 임시저장으로 아이디가 넘어온 경우
    //   }
    // }
    const paramIds = this.$route.params.id.split('-')
    if (paramIds.length > 1) {
      this.articleId = parseInt(paramIds[0], 10)
      this.getArticle(this.articleId)
    } else {
      if (this.$route.params.id === 'new') {
        this.init() // !! 새로작성인 경우 초기화 함수 호출
      } else {
        this.getArticle(this.$route.params.id) // 임시저장으로 아이디가 넘어온 경우
      }
    }
  },

  methods: {
    isSpKey, // 특수문자 필터
    isNumber, // 숫자만 인지 체크하는 필터
    strDateFormat2,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    // 목록으로 - 주의: shop 보내는 것 주의
    goList () {
      this.$router.push(`/${currDir}`)
    },
    // 중요: 리스트를 리프레시 한다.
    refreshList (param = '') {
      this.$router.push(`/${currDir}/${param}`)
    },
    // 구분: 팀정보 에러시 처리하는 함수
    async popTeamError (msg) {
      try {
        this.isId = false
        this.overlay = true
        // 팝업 에러창
        const pop = await this.findParentRefs(this.$parent, 'ConfirmDialogNoCancel')
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        if (await pop.open('접근권한', msg, { color: 'warning', width: 400 })) {
          this.refreshList(`LR`)
        } else {
          this.refreshList(`LR`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 초기화
    async init () {
      try {
        // form값 초기화
        this.form.id = 0
        this.form.depth = 1 // 횟수
        this.form.tern = 1 // 결재라인순서
        this.form.status = 1 // 상태(아이디가 있으면 임시저장, 없으면 작성중)
        this.form.gubun1 = '작성중' // 상태문자열
        this.form.subject = '' // 제목
        this.form.code = '' // 내부결재번호(코드)
        this.form.content = null // 내용
        this.form.rId = 0 // 연계정보 아이디
        this.form.rType = 0 // 연계정보 타입
        this.form.rInfo = '' // 연계정보 표시
        this.form.rTid = 0 // 연계정보 팀 아이디
        this.form.isStar = 0 // 중요표시별

        // 내부결재번호 중복체크
        this.mustChk = false
        this.chkMulti = false
        this.isOverlabed = false

        this.isTempSave = false // 임시저장 여부 플래그(최초 저장시 제목입력하면 자동으로 지정됨)

        this.currStatusColor = 'grey darken-1'
        this.currStatusIcon = 'mdi-content-save-cog'

        this.form.approvers = []
        this.form.viewers = []

        this.form.isApproverChanged = false
        this.form.isViewerChanged = false

        this.oldApprovers = ''
        this.oldViewers = ''

        this.isApproverError = false // 결재자에 오류가 있는지 여부

        this.myTids = []

        this.relData = '' // 연계데이터 정보 초기화

        // 중요: 연계정보가 넘어온 경우 처리
        if (this.$route.params.rType && this.$route.params.rId) {
          this.form.rType = this.$route.params.rType
          this.form.rId = this.$route.params.rId
          this.form.rInfo = this.$route.params.rInfo
          this.form.rTid = this.$route.params.rTid

          // !![2021.7.15]연계데이터 정보는 이제 JSON 문자열이므로 파싱해서 처리해야 한다.
          if (this.form.rId && this.form.rType) {
            const tmpInfo = JSON.parse(this.form.rInfo)
            if (this.form.rType === 1) {
              // 소송
              this.relData = `${tmpInfo.caseName} - ${tmpInfo.courtName} ${tmpInfo.caseNum}${tmpInfo.managenum ? `(${tmpInfo.managenum})` : ''}`
            } else if (this.form.rType === 2) {
              // 자문
              this.relData = `${tmpInfo.caseName} - ${tmpInfo.manageNum}`
            } else if (this.form.rType === 3) {
              // 인명부
              this.relData = tmpInfo.name
            }
          }

          // !! 임시저장 - 새로작성(id가 저장되지 않았다) && 연계정보가 넘어온 경우
          // !! 아래 처럼 제목을 지정하는 것 만으로 watch 의 'form.subject' 감시가 발동하여 자동 임시저장된다.
          if (this.form.id === 0 && !this.isTempSave) {
            // 제목의 길이가 30이니 주의할 것!
            this.$nextTick(() => {
              this.form.subject = '임시저장 - 제목을 변경하세요'
            })
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 제목 등록시 임시저장 함수 호출! 최초 등록시 1번만 실행되야 정상이다!
    async setTempSave () {
      try {
        // 상태문자열만 '임시저장'으로 변경한다!
        this.form.gubun1 = '임시저장'
        this.currStatusColor = this.statusColors[this.form.status] // 상태색상적용
        this.currStatusIcon = this.statusIcon[this.form.status]

        // console.log(this.form)
        const { data } = await this.$axios.post(`lawork/eapprove/saveTmpFirst`, this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        // !! 백앤드에서 받아온 아이디로 리스트와 뷰를 리프레시 시킨다
        this.refreshList(`${data.insertId}-E`)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: id로 게시물을 가져온다
    async getArticle (id) {
      try {
        if (!id) throw new Error('[ERR-0] 잘못된 인수형식 입니다.')

        const { data } = await this.$axios.get(`lawork/eapprove/getEaContentById/${id}`)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        if (data.article) {
          const { article } = data
          this.form.id = article.id
          this.form.depth = article.depth
          this.form.tern = article.tern
          this.form.status = article.status
          this.form.gubun1 = article.gubun1
          this.form.subject = article.subject
          this.form.code = article.code
          this.form.content = article.content
          this.form.rId = article.rId
          this.form.rType = article.rType
          this.form.rInfo = article.rInfo
          this.form.rTid = article.rTid
          this.form.isStar = article.isStar

          // 결재자 배열
          this.form.approvers = data.approvers
          // 회람자 배열
          this.form.viewers = data.viewers

          // !! 결재자 중에 오류인 넘이 하나라도 있으면 true
          this.isApproverError = data.isApproverError

          // !! 2021.2.22 연계데이터가 있고 연계데이터가 삭제된 경우 true
          this.isDelRelation = data.isDelRelation

          // 결재자, 회람자 변동여부도 초기화 한다.
          this.form.isApproverChanged = false
          this.form.isViewerChanged = false

          // 상태색상적용
          this.currStatusColor = this.statusColors[this.form.status]

          // 중요: 서브 컨텐츠인 댓글,첨부파일,공유링크 목록 패칭
          await this.getSubContents(36) // 첨부파일 + 공유링크
          await this.getSubContents(37) // 댓글

          // !! 결재자, 회람자 변동 비교를 위해 문자열로 만들어 따로 변수에 넣어놓는다!
          this.oldApprovers = JSON.stringify(data.approvers)
          this.oldViewers = JSON.stringify(data.viewers)

          // 중요: 로그인 유저가 속한 팀의 정보를 가져온다. 그중 팀아이디만 따로 this.myTids 배열에 담는다.
          await this.getAllTeamInfoByEmail(this.$store.state.ui.email).then(({ teams }) => {
            if (teams && teams.length > 0) {
              this.myTids = teams.map(t => t.id) // 아이디만 간추리는 작업
            }
          })

          // !![2021.7.15]연계데이터 정보는 이제 JSON 문자열이므로 파싱해서 처리해야 한다.
          if (this.form.rId && this.form.rType) {
            const tmpInfo = JSON.parse(this.form.rInfo)
            if (this.form.rType === 1) {
              // 소송
              this.relData = `${tmpInfo.caseName} - ${tmpInfo.courtName} ${tmpInfo.caseNum}${tmpInfo.managenum ? `(${tmpInfo.managenum})` : ''}`
            } else if (this.form.rType === 2) {
              // 자문
              this.relData = `${tmpInfo.caseName} - ${tmpInfo.manageNum}`
            } else if (this.form.rType === 3) {
              // 인명부
              this.relData = tmpInfo.name
            }
          }

          // !! 내부결재번호 중복체크를 한걸로다가 ~ 시간상 지연을 주기위해 nextTick() 사용
          this.$nextTick(function () {
            if (article.code) {
              this.mustChk = false // 중복체크 필요없다
              this.chkMulti = true // 중복체크를 했다
              this.isOverlabed = false // 중복되지 않았다
            } else {
              this.mustChk = false
              this.chkMulti = false
              this.isOverlabed = false
            }
          })
        } else {
          // !! 이미 삭제된 데이터인 경우 처리
          await this.popTeamError(`진행할 수 없습니다!<br>삭제된 데이터인지 확인하시기 바랍니다.`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 상신버튼 클릭 이벤트 핸들러 - 초기화 하고 목록으로 이동한다.
    // 필수값인 제목 체크
    // 내부결재번호의 중복체크
    // 결재자배열이 비어있으면 안된다!
    // !! form.status=2 이고 form.gubun1='결재중' 으로
    async sendApproval () {
      try {
        // 참고: 필수 입력값 검증 - 제목만 해당
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) {
          this.$refs.txtSubject.focus()
          throw new Error('입력값을 확인해 주세요')
        }

        // !! DB에 저장되지 않은 경우 등록 불가.
        if (!this.form.id) {
          throw new Error('잠시만 기다리시면 자동으로 임시저장 됩니다.')
        }

        // !! 제목은 한 번 더 체크
        if (!this.form.subject) {
          this.$refs.txtSubject.focus()
          throw new Error(this.regErrMsg)
        }

        // !! 내부결재번호 중복체크 확인
        if (this.mustChk && !this.chkMulti) { // 중복체크를 해야 하는데 하지 않았다
          this.isOverlabed = true // 중복여부를 가져오진 않았지만 수동으로 에러를 만든다.
          this.$refs.txtCode.focus()
          throw new Error('중복체크를 하셔야 합니다.')
        }

        // !! 결재자 배열이 비어있는지 체크. 비어있으면 강제로 결재자 팝업을 연다.
        if (this.form.approvers.length === 0) {
          // this.$store.commit('SB_POP', { msg: '결재자를 선택하셔야 합니다.' })
          sleep(300).then(() => {
            this.setEalinePopup(1)
          })
          throw new Error('결재자를 선택하셔야 합니다. 결재자가 없는 상신은 불가능 합니다.')
        }

        // !! 중요: 상태를 '상신'으로 변경한다.
        this.form.status = 2
        this.form.gubun1 = '결재중' // 역시 '결재중' 으로 저장해야 한다
        this.form.tern = 2 // 중요: 순서를 2로 하여 다은 턴으로 넘겨야 한다!

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `결재를 상신 하시겠습니까?`
        if (await pop.open('상신?', msg, { color: 'info', width: 400 })) {
          // 상신(수정) 시키고 결재함 리스트로 이동시킨다!
          const { data } = await this.$axios.post(`lawork/eapprove/editApproval`, this.form)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.isDelRelation) {
            // 중요: 2021.2.22 추가 - 소송/자문/인명부 연계결재인데 연계된 데이터가 삭제된 경우 처리
            if (await pop.open('연계정보', `연계된 <b>${data.chInfo.info2}</b>는 삭제되었습니다.<br>확인하시고 결재를 재작성해 주시기 바랍니다.`, { color: 'warning', width: 400 })) {
            }
          } else {
            sleep(600).then(() => {
              this.redirect('/ealist')
            })
          }
        }
      } catch (e) {
        // this.sbpop(e)
        this.$store.commit('SB_POP', { msg: e.message, color: 'error', timeout: 6000, shaped: true })
      }
    },
    // 구분: 임시저장 버튼 이벤트 핸들러
    // 이 단계에선 필수값인 제목의 체크와 내부결재번호의 중복체크등이 필요하다.
    // 단, 결재자는 비어있어도 상관없다.
    // !! form.status=1 이고 form.gubun1='임시저장' 으로 보낸다.
    async setImsiSave () {
      try {
        // 참고: 필수 입력값 검증 - 제목만 해당
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) {
          this.$refs.txtSubject.focus()
          throw new Error('입력값을 확인해 주세요')
        }

        // !! DB에 저장되지 않은 경우 등록 불가.
        if (!this.form.id) {
          throw new Error('잠시만 기다리시면 자동으로 임시저장 됩니다.')
        }

        // !! 제목은 한 번 더 체크
        if (!this.form.subject) {
          this.$refs.txtSubject.focus()
          throw new Error(this.regErrMsg)
        }

        // !! 내부결재번호 중복체크 확인
        if (this.mustChk && !this.chkMulti) { // 중복체크를 해야 하는데 하지 않았다
          this.isOverlabed = true // 중복여부를 가져오진 않았지만 수동으로 에러를 만든다.
          this.$refs.txtCode.focus()
          throw new Error('중복체크를 하셔야 합니다.')
        }

        // 임시저장(수정) 시키고 작성함 리스트로 이동시킨다!
        const { data } = await this.$axios.post(`lawork/eapprove/editApproval`, this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        if (data.isDelRelation) {
          // 중요: 2021.2.22 추가 - 소송/자문/인명부 연계결재인데 연계된 데이터가 삭제된 경우 처리
          const pop = await this.findParentRefs(this.$parent, 'confirmDialog')
          if (!pop) throw new Error('팝업창을 열 수 없습니다.')
          if (await pop.open('연계정보', `연계된 <b>${data.chInfo.info2}</b>는 삭제되었습니다.<br>확인하시고 결재를 재작성해 주시기 바랍니다.`, { color: 'warning', width: 400 })) {
          }
        } else {
          // !! 백앤드에서 받아온 아이디로 리스트와 뷰를 리프레시 시키고 메시지를 띄운다음 리스트로 이동한다.
          this.refreshList(`${data.affectedId}-E`)
          this.$store.commit('SB_POP', { msg: '임시저장 되었습니다.', color: 'success' })
          sleep(600).then(() => {
            this.goList()
          })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 취소 버튼 이벤트 핸들러
    // 1) form.id 가 없으면 초기화 하고 목록으로 이동한다.
    // 2) 있으면 의견(댓글),첨부파일,공유링크,결재자목록을 모두 지우고 해당 결재도 삭제 후 목록으로 이동한다.
    async cancel () {
      try {
        if (!this.form.id) {
          // 1) 아이디가 없으면 초기화 후 목록이동
          await this.init()
          this.goList()
        } else {
          // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
          const target = 'confirmDialog'
          const pop = await this.findParentRefs(this.$parent, target)
          if (!pop) throw new Error('팝업창을 열 수 없습니다.')
          // 찾았으면 팝업을 연다
          const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
          if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
            // 아이디가 있으면 연관된 것들 삭제 후 이동
            const { data } = await this.$axios.get(`/lawork/eapprove/deleteApproval/${this.form.id}`)
            if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
            sleep(200).then(() => {
              this.refreshList(`LR`)
            })
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 내부결재번호 중복체크
    async chkEaCodeOverlap () {
      try {
        // !! this.mustChk = false 면 중복체크가 필요없으므로 DB 쿼리를 하지 않는다.
        if (this.form.code.length > 0 && this.mustChk) {
          const { data } = await this.$axios.get(`lawork/eapprove/chkEaCodeOverlap/${this.form.code}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.chk) { // 중복 되었다
            this.mustChk = true // 중복되었으니 다시 체크해야 한다
            this.chkMulti = false // 중복확인 여부 > 중복체크를 다시 해야 한다
            this.isOverlabed = true // 중복되었다.
            this.$refs.txtCode.focus()
            throw new Error(`이미 등록된 결재번호입니다.`)
          } else {
            this.mustChk = false
            this.chkMulti = true
            this.isOverlabed = false
            this.$store.commit('SB_POP', { msg: '등록할 수 있는 결재번호입니다.', color: 'success' })
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 결재라인 세팅 팝업 열기
    // type=1 은 결재자, 2는 회람자 선택
    async setEalinePopup (type) {
      try {
        // !! DB에 저장되지 않은 경우 -> '작성중' 이거나 제목이 비어있는 경우는 등록 불가
        if (!this.form.id || !this.form.subject) {
          throw new Error(this.regErrMsg)
        }

        // 중요:!! 결재자의 경우 객체를 품고있는 복잡한 배열이라 deepCopyComplexArray() 를 사용해 깊은복사를 한다
        const copyApprovers = this.deepCopyComplexArray(this.form.approvers)
        // 1차 배열인 회람자는 slice() 로 배열의 깊은 복사를 한다!
        const copyViewers = this.form.viewers.slice()

        const param = {
          type,
          id: this.form.id, // 결재컨텐츠의 아이디
          rTid: this.form.rTid, // 연계 팀 아이디
          approvers: copyApprovers,
          viewers: copyViewers
        }
        const result = await this.$refs.setEalineDialog.open('결재라인', { width: 950, height: 700 }, param)
        if (result === 'cancel') {
          // 취소하면 'cancel' 문자열이 날라온다. 아니면 [] 배열객체가 넘어온다.
        } else {
          // !! 결재자와 회람자 정보가 변경되서 넘어왔다
          this.form.approvers = this.deepCopyComplexArray(result.approvers)
          this.form.viewers = result.viewers.slice()

          // 중요: 팝업이 닫히면서 결재자, 회람자의 변동을 체크한다.
          this.form.isApproverChanged = !(this.oldApprovers === JSON.stringify(this.form.approvers))
          this.form.isViewerChanged = !(this.oldViewers === JSON.stringify(this.form.viewers))

          // 중요: 결재자 루프를 돌면서 오류처리
          if (this.isApproverError) {
            let tmpFlag = false
            this.form.approvers.forEach(approver => {
              approver.items.forEach(v => {
                if (v.result === 4) {
                  tmpFlag = true
                }
              })
            })
            this.isApproverError = tmpFlag
          }

          // 사용안함:[2021.9.17] 사진을 보이도록 다시 불러야 하네..
          // this.getArticle(this.form.id)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 중요: 복잡한 배열의 깊은 복사를 위한 함수
    deepCopyComplexArray (arr) {
      let copy = {}
      if (Array.isArray(arr)) {
        copy = arr.slice().map(v => {
          return this.deepCopyComplexArray(v)
        })
      } else if (typeof arr === 'object' && arr !== null) {
        for (let attr in arr) {
          if (arr.hasOwnProperty(attr)) {
            copy[attr] = this.deepCopyComplexArray(arr[attr])
          }
        }
      } else {
        copy = arr
      }
      return copy
    },
    // --- 구분: 서브 컨텐츠: 댓글, (첨부파일 + 공유링크) 처리 ---
    // !! 첨부파일(36),공유링크(38) 은 둘중 어느것을 선택하더라도 같이 처리된다. attachedFiles[] 배열에 들어감!
    async getSubContents (type = null) {
      try {
        const columns = { pId: this.form.id, pType: 5, type }
        const { data } = await this.$axios.post(`lawork/eapprove/getSubContents`, columns)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

        // !! 그냥 (첨부파일+공유링크), 댓글로 처리한다.
        if (type === 36 || type === 38) {
          this.attachedFiles = data.list
        } else if (type === 37) {
          this.comments = data.list
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // --- 구분: 히스토리 팝업 ---
    // 기존에 결재라인이 있는경우 즉 depth > 1 이다
    async viewHistoryPop (evt) {
      try {
        // !! 안열린 상태에서만 연다. - 열린상태에서 다시 열지 못하도록
        if (!this.$refs.viewHistoryPop.showMenu) {
          await this.$refs.viewHistoryPop.show(evt) // 일단 열고

          // pId, depth 를 보낸다
          // 주의: 결재함과 다르게 depth - 1 로 보낸다. 기존의 마지막 차수를 가져오기 위해
          const params = { pId: this.form.id, depth: this.form.depth - 1 }
          await this.$refs.viewHistoryPop.setData(params)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 연계정보 상세페이지 바로가기
    gotoDetail () {
      // !! 넘어온 rType 에 맞게 소송/자문/인명부/거래처로 이동한다
      // !! 로그인 유저가 소속된 팀을 조사하여 해당 연계정보에 권한이 있는지 확인할 것
      // this.form.rTid - 에는 연계 컨텐츠가 속한 팀 정보가 들어있다.
      if (this.myTids.includes(this.form.rTid)) {
        // 권한이 있다.
        if (this.form.rType && this.form.rId) {
          const typeCheck = {
            1: 'case',
            2: 'advice',
            3: 'client',
            46: 'shop'
          }
          this.$router.push(`/${typeCheck[this.form.rType]}/${this.form.rId}`)
        }
      } else {
        // 권한이 없다!
        const msg = '연계정보에 대한 접근 권한이 없습니다.'
        this.$store.commit('SB_POP', { msg, color: 'error', timeout: 5000, shaped: true })
      }
    },
    // 구분: 로그인 유저의 소속팀목록 패칭
    async getAllTeamInfoByEmail (email) {
      try {
        const { data } = await this.$axios.get(`admin/staff/getAllTeamInfoByEmail/${email}`)
        if (!data.success) throw new Error(`list error: ${data.message}`)

        return { teams: data.teams }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // --- 구분: 댓글 ---
    // 댓글 팝업 열기
    async showCommentPop (evt) {
      try {
        // !! 안열린 상태에서만 연다. - 열린상태에서 다시 열지 못하도록
        if (!this.$refs.addCommentPop.showMenu) {
          await this.$refs.addCommentPop.show(evt) // 일단 열고

          // !! 나머지 인자를 보낸다.
          // teamId 는 전자결재에선 의미가 없으므로 1로 하드코딩해서 보낸다.
          // pId 는 현재 전자결재의 아이디를 넘긴다.
          const params = { teamId: 1, pId: this.form.id }
          const result = await this.$refs.addCommentPop.setData(params)
          if (result) {
            await this.getSubContents(37) // !! 댓글 목록 리프레시
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 댓글 삭제
    // 주의: 삭제는 case/deleteLwcommon 를 사용한다!
    async deleteComment (commentId) {
      try {
        if (!commentId) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          const { data } = await this.$axios.get(`lawork/case/deleteLwcommon/${commentId}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          // 정상적으로 등록되었으면 리프레시
          await this.getSubContents(37) // !! 댓글 목록 리프레시
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // --- 구분: 첨부파일 ---
    // 첨부파일 팝업열기
    async showFilePop (evt) {
      try {
        // !! 안열린 상태에서만 연다. - 열린상태에서 다시 열지 못하도록
        if (!this.$refs.addFilePop.showMenu) {
          await this.$refs.addFilePop.show(evt) // 일단 열고

          // !! 나머지 인자를 보낸다.
          // teamId 는 전자결재에선 의미가 없으므로 1로 하드코딩해서 보낸다.
          // pId 는 현재 전자결재의 아이디를 넘긴다.
          const params = { teamId: 1, pId: this.form.id }
          const result = await this.$refs.addFilePop.setData(params)
          if (result) {
            await this.getSubContents(36) // 첨부파일 목록 리프레시
          }
        }
      } catch (err) {
        this.sbpop(err)
      }
    },
    // 첨부파일 삭제
    async deleteFile (commentId) {
      try {
        if (!commentId) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>관리자의 구글 드라이브에는 유지됩니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          const { data } = await this.$axios.get(`lawork/case/deleteLwcommon/${commentId}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          // 리프레시
          await this.getSubContents(36)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 첨부파일 다운로드
    async fileDownload (file) {
      try {
        if (!file.str5) throw new Error(`유효한 파일이 아닙니다.`)
        this.overlay = true // 로딩 오버레이 on
        const webContentLink = file.str5 // 이건 다운로드

        if (this.$store.state.ui.lv === 'M') {
          // !! 관리자인 경우는 그냥 다운로드
          document.location.href = webContentLink // 다운로드 시작!
          // 2초간 로딩 딜레이
          setTimeout(() => {
            this.overlay = false // 로딩 오버레이 off
          }, 2000)
        } else {
          // !! 관리자가 아닌 경우 .. 공유요청
          const formData = {
            fileId: file.str2 // 구글 드라이브 파일 아이디
          }
          const { data } = await this.$axios.post(`lawork/eapprove/requestGdFileShare`, formData)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.permessionId) {
            document.location.href = webContentLink // 다운로드 시작!
            // 2초간 로딩 딜레이
            setTimeout(() => {
              this.overlay = false // 로딩 오버레이 off
            }, 2000)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 첨부파일 바로보기 - 브라우저에서 바로보기
    async fileDirectView (file) {
      try {
        if (!file.str6) throw new Error(`유효한 파일이 아닙니다.`)
        const webViewLink = file.str6 // 이건 브라우저에서 바로보기

        if (this.$store.state.ui.lv === 'M') {
          // !! 관리자인 경우
          const win = window.open(webViewLink, '_blank')
          win.focus()
        } else {
          // !! 관리자가 아닌 경우 .. 공유요청
          const formData = {
            fileId: file.str2 // 구글 드라이브 파일 아이디
          }
          const { data } = await this.$axios.post(`lawork/eapprove/requestGdFileShare`, formData)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.permessionId) {
            const win = window.open(webViewLink, '_blank')
            win.focus()
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // --- 구분: 공유링크 처리 ---
    // 공유링크 팝업열기
    async showShareLinkPop (evt) {
      try {
        // !! 안열린 상태에서만 연다. - 열린상태에서 다시 열지 못하도록
        if (!this.$refs.addShareLinkPop.showMenu) {
          await this.$refs.addShareLinkPop.show(evt) // 일단 열고

          // !! 나머지 인자를 보낸다.
          // teamId 는 전자결재에선 의미가 없으므로 1로 하드코딩해서 보낸다.
          // pId 는 현재 전자결재의 아이디를 넘긴다.
          const params = { teamId: 1, pId: this.form.id }
          const result = await this.$refs.addShareLinkPop.setData(params)
          if (result) {
            await this.getSubContents(38) // 목록 리프레시
          }
        }
      } catch (err) {
        this.sbpop(err)
      }
    },
    // 공유링크 바로가기 처리
    async shareLinkDirectGo (item) {
      try {
        // console.log(item)
        if (!item.str2) throw new Error(`유효한 공유주소가 아닙니다.`)

        // * [2022.6.7 수정] http(s):// 가 없는 경우 자동으로 붙여준다.
        let sLink = item.str2
        let httpRegex = /^(http(s)?:\/\/)/
        if (!httpRegex.test(String(item.str2).toLowerCase())) {
          sLink = 'https://' + sLink
        }

        // 다른 탭에서 링크 열기
        const link = document.createElement('a')
        link.href = sLink
        link.target = '_blank'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 공유링크 삭제
    async deleteShareLink (commentId) {
      try {
        if (!commentId) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          const { data } = await this.$axios.get(`lawork/case/deleteLwcommon/${commentId}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          // 리프레시
          await this.getSubContents(38)
        }
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style>
/*
  !!참고: 모든 테이블에 공통적용되는 코드지만 각 파일에 있어야 한다.
  새로고침하면 적용이 안되고 적용된 페이지를 들러야 한다.
  v-data-talbe td 의 왼쪽,오른쪽 패딩 제거. 단 style 태그의 scoped 속성을 지워야 적용됨
*/
/* .v-data-table td{ 2020.6.15 변경됨*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 0;
  padding-right: 0;
}
.v-overflow-btn .v-select__selection--comma:first-child {
  margin-left: 5px;
  margin-right: 0px;
}
.v-overflow-btn .v-input__append-inner {
  width: 30px;
}
/* 중요: 모바일에서 테이블의 기본 값은 justify-content: space between 이다. 이걸 start 로 변경한다! */
.v-data-table__mobile-row {
  justify-content: start;
}
/*
  참고: 모든 vue2editor 뷰어에 공통적용
*/
#vue2editorViewer p {
  margin-bottom: 0px !important;
}
#vue2editorViewer2 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
#vue2editorViewer3 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
</style>
