<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <!-- <v-btn
        v-if="index > -1"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn> -->

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <!-- <v-btn
        slot="star-area"
        icon
        @click.native="form.isStar = !form.isStar"
      >
        <v-icon
          v-if="!form.isStar"
          color="grey lighten-1"
        >
          mdi-star-outline
        </v-icon>
        <v-icon
          v-else
          color="yellow"
        >
          mdi-star
        </v-icon>
      </v-btn> -->

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <!-- <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto py-2 px-3"
        :max-height="options.height"
      >
        <v-card-text> -->

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <v-card-text
        slot="content"
        class="ma-0 pa-0"
      >
        <v-container fluid class="ma-0 pa-0"><!-- 여기까지 적용 -->

          <v-row
            align="start"
            justify="center"
            class="ma-0 pa-1"
          >
            <!-- <v-col cols="12" class="hidden-xs-only">
              <v-alert
                text
                dense
                outlined
                color="info"
                icon="info"
                class="ma-0"
              >
                <div class="text-body-2">
                  팀원목록에서 현재 로그인한 유저는 포함되지 않습니다.
                </div>
              </v-alert>
            </v-col> -->
            <!-- 왼쪽 -->
            <v-col cols="6">

              <v-row no-gutters>
                <v-col cols="12">
                  <v-btn
                    :outlined="selType !== 1"
                    :dark="selType === 1"
                    color="teal darken-2"
                    class="mb-1"
                    @click.stop="setType(1)"
                  >
                    <v-icon small left class="mr-2">
                      {{ selType === 1 ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                    </v-icon>
                    결재자
                  </v-btn>
                  <v-tooltip
                    right
                    color="primary"
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small class="mb-1 ml-2" color="primary" style="cursor:pointer;">mdi-help-circle-outline</v-icon>
                    </template>
                    <span class="text-body-2">공동결재의 경우, 이름을 끌어다 동일선상에 놓으면 됩니다.</span>
                  </v-tooltip>
                  <v-card
                    outlined
                    :elevation="selType === 1 ? 5 : 0"
                    max-height="350"
                    min-height="350"
                    class="overflow-y-auto text-body-2 pa-4"
                  >
                    <draggable
                      :list="approvers"
                      :disabled="!dndEnabled"
                      @start="dragging = true"
                      @end="dragging = false"
                      @update="orderChanged = true"
                      @change="changeRows"
                    >
                      <v-row
                        v-for="row in approvers"
                        :key="row.index"
                        class="my-0"
                      >
                        <v-alert
                          color="primary lighten-3"
                          dense
                          text
                          border="left"
                          class="d-block mb-1 py-2 pl-3 pr-2"
                          min-width="410"
                          style="cursor: move;"
                        >
                          <draggable
                            :list="row.items"
                            :group="{ name: 'row' }"
                          >
                            <v-chip
                              v-for="(item, i) in row.items"
                              :key="i"
                              label
                              outlined
                              :color="item.result === 4 ? 'error' : 'teal darken-2'"
                              class="ma-1"
                              close
                              @click:close="rmApprover(item, i, row.index)"
                              style="cursor: pointer;"
                            >
                              {{ item.name }}
                              <span class="mx-1 text-caption grey--text text--darken-1">
                                {{ item.rank }}
                              </span>
                            </v-chip>
                          </draggable>
                        </v-alert>
                      </v-row>
                    </draggable>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <div class="my-4"></div>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    :outlined="selType !== 2"
                    :dark="selType === 2"
                    color="brown darken-2"
                    class="mb-1"
                    @click.stop="setType(2)"
                  >
                    <v-icon
                      small
                      left
                      class="mr-2"
                    >
                      {{ selType === 2 ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                    </v-icon>
                    회람자
                  </v-btn>
                  <v-card
                    outlined
                    :elevation="selType === 2 ? 5 : 0"
                    max-height="150"
                    min-height="150"
                    class="overflow-y-auto text-body-2 pa-2"
                  >
                    <v-chip
                      v-for="(item, i) in viewers"
                      :key="i"
                      label
                      outlined
                      color="brown darken-2"
                      class="ma-2"
                      close
                      @click:close="rmViewer(item, i)"
                      style="cursor: pointer;"
                    >
                      {{ item.name }}
                      <span class="mx-1 text-caption grey--text text--darken-1">
                        {{ item.rank }}
                      </span>
                    </v-chip>
                  </v-card>
                </v-col>
              </v-row>

            </v-col>
            <!-- 오른쪽 -->
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-select
                    outlined
                    v-model="myTeam"
                    :items="myTeams"
                    item-value="value"
                    item-text="text"
                    hide-details
                    label="업무부서"
                    class="pb-1"
                    dense
                    style="font-size: 0.785rem !important"
                    @change="changeTeam"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-card
                    outlined
                    elevation="0"
                    max-height="550"
                    min-height="550"
                    class="overflow-y-auto text-body-2 pa-3"
                  >
                    <v-alert v-show="teamStaffs.length <= 0"
                      text color="blue-grey" class="text-body-2"
                    >
                      해당 정보가 없습니다.
                    </v-alert>
                    <v-row
                      v-for="(staff, i) in teamStaffs"
                      :key="i"
                    >
                      <v-chip
                        label
                        outlined
                        color="indigo darken-3"
                        class="d-block ma-2"
                        @click.stop="clickUser(staff, i)"
                      >
                        <v-avatar left>
                          <v-icon>mdi-arrow-left-drop-circle</v-icon>
                        </v-avatar>
                        {{ staff.name }}
                        <span class="mx-1 text-caption grey--text text--darken-1">
                          {{ staff.rank }}
                        </span>
                      </v-chip>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>

          </v-row>

        <!-- 상단 타이틀 고정을 풀때 사용 -->
        </v-container>
      </v-card-text>

        <!-- 상단 타이틀 고정시 사용 -->
        <!-- </v-card-text>
      </v-card> -->

    </baseModal>

  </v-dialog>
</template>

<script>
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그
import draggable from 'vuedraggable' // drag & drop

export default {
  components: {
    baseModal,
    draggable
  },

  props: [],

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 650,
      height: 600,
      zIndex: 200
    },
    // 구분: 선택한 타입
    selType: 1, // 1 - 결재자, 2 - 회람자
    // 구분: 팀소속원 배열
    teamStaffs: [],
    // 구분: 결재자 배열
    approvers: [],
    // 구분: 회람자 배열
    viewers: [],
    // 구분: Form 관련
    form: {
      approvers: [],
      viewers: []
    },
    // 구분: 나의 팀 초기화
    myTeam: 1,
    myTeams: [
      { text: '기본팀', value: 1 }
    ],
    // 구분: 드래그 앤 드랍
    dndEnabled: true, // dnd 사용여부
    dragging: false,
    orderChanged: false // 순서변경여부
  }),

  watch: {
    // 중요: this.approvers 의 items 배열안에 있는 item 칩을 이동시킬때는 watch 로 해야 한다.
    approvers: {
      handler (curr, prev) {
        if (curr.length > 0) {
          // !! 순회하며 items 가 비어있는 this.approvers 를 삭제
          for (let row of this.approvers) {
            if (row.items.length === 0) { // items 가 비었으면
              // console.log(row.index)
              this.approvers.splice(row.index - 1, 1) // this.approvers 에서 삭제
            }
          }

          // !! this.approvers 의 index 를 순서대로 재지정
          let idx = 1
          for (let row of this.approvers) {
            row.index = idx
            idx++
          }
        }
      },
      deep: true
    }
  },

  mounted () {
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async init () {
      // validation 초기화
      this.$validator.reset()

      this.selType = 1 // 1 - 결재자, 2 - 회람자 선택타입

      this.teamStaffs = [] // 팀원배열
      this.approvers = [] // 결재자 배열
      this.viewers = [] // 회람자 배열

      // Form 관련
      this.form.approvers = []
      this.form.viewers = []

      // 나의 팀 초기화
      this.myTeam = 1
      this.myTeams = [ { text: '기본팀', value: 1 } ]

      // 드래그 앤 드랍
      this.dndEnabled = true // dnd 사용여부
      this.dragging = false
      this.orderChanged = false // 순서변경여부
    },
    async open (title, options, obj = {}) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // 초기화
        await this.init()

        // !! 넘어온 선택 타입
        this.selType = obj.type

        // !! 자신이 속한 모든 팀리스트를 가져온다
        await this.getMyAllTeams()

        // !! 넘어온 obj로 결재자와 회람자 배열을 채운다.
        this.approvers = obj.approvers
        this.viewers = obj.viewers
        // !! 폼값의 결재자, 회람자 배열도 똑같이 채워줘야 한다.
        this.form.approvers = this.approvers
        this.form.viewers = this.approvers

        // !! 현재 선택한 팀아이디로 팀원을 매칭한다. - 처음엔 기본팀이 매칭된다
        if (obj.rTid) {
          // !![2021.7.15] 팀아이디가 넘어온 경우 자동선택
          this.myTeam = obj.rTid
        }
        await this.getTeamStaffs(this.myTeam)

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 등록
    async agree () {
      try {
        // form 값을 만들어서 리턴하자
        this.form.approvers = this.approvers
        this.form.viewers = this.viewers

        // 등록 후
        // await this.init() // 초기화하면 안된다!!
        this.resolve(this.form) // this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소
    async cancel () {
      try {
        // console.log(this.approvers)
        this.resolve('cancel') // 'cancel' 이라는 문자열을 던진다! reject() 가 아니라 resolve(false)로 던져야 한다.
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 사용안함: 삭제 - 이 폼에선 사용하지 않는다
    // async remove () {
    //   try {
    //     if (!this.form.id) throw new Error(`잘못된 요청입니다.`)

    //     // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
    //     const target = 'confirmDialog'
    //     const pop = await this.findParentRefs(this.$parent, target)
    //     if (!pop) throw new Error('팝업창을 열 수 없습니다.')
    //     // 찾았으면 팝업을 연다
    //     const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
    //     if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
    //       // console.log(this.form)
    //       const { data } = await this.$axios.post(`lawork/case/deleteUpmuContent`, this.form)
    //       if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

    //       await this.init() // 초기화
    //       // this.resolve(true)
    //       this.resolve('removed')
    //       this.dialog = false
    //     }
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 구분: 타입 지정
    async setType (type) {
      try {
        this.selType = type

        // !! 결재자가 아니면 드래그 사용불가
        if (type !== 1) {
          this.dndEnabled = false
        } else {
          this.dndEnabled = true
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 자신이 속한 모든 팀을 가져온다(기본팀부터 순서대로 가져온다. 팀이름과 id 만 필요)
    async getMyAllTeams () {
      try {
        const { data } = await this.$axios.get('lawork/eapprove/getMyAllTeams')
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
        let tmp = []
        if (data.teamInfos && data.teamInfos.length > 0) {
          for (let d of data.teamInfos) {
            if (d.id !== 4) { // !![2021.10.20] 회의실관리팀 숨기기 - 차후 풀어준다.
              tmp.push({ text: d.name, value: d.id })
            }
          }
        }
        this.myTeams = tmp
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 팀아이디로 팀원을 가져온다. 여기서는 현재 로그인한 유저는 빼고 ..
    async getTeamStaffs (tId) {
      try {
        if (!tId) throw new Error(`오류가 발생하였습니다! [팀아이디 누락]`)

        const { data } = await this.$axios.get(`lawork/eapprove/getTeamStaffsByTid/${tId}`)
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
        let tmp = []
        if (data.teamStaffs && data.teamStaffs.length > 0) {
          // 1) 결재자 배열에서 이메일만 따서 배열로 만들자
          let tmpApprovers = []
          if (this.approvers.length > 0) { // 결재자가 있는 경우만
            this.approvers.forEach(approver => {
              approver.items.forEach(v => {
                tmpApprovers.push(v.email)
              })
            })
          }

          // 2) 회람자 배열에서 이메일만 따서 배열로 만들자
          let tmpViewers = []
          if (this.viewers.length > 0) { // 회람자가 있는 경우만
            tmpViewers = this.viewers.map(v => v.email)
          }

          for (let d of data.teamStaffs) {
            if (d.email === this.$store.state.ui.email) { // !! 현재 유저는 스킵
              continue
            }

            // !! 결재자나 회람자에 존재하면 스킵
            if (tmpApprovers.includes(d.email) || tmpViewers.includes(d.email)) {
              continue
            }

            tmp.push({ email: d.email, name: d.name, rank: d.rank, result: 0 }) // result 는 강제로 0을 넣어준다
          }
        }

        // 제외하고 남은 유저를 팀원배열에 매칭한다.
        this.teamStaffs = tmp
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 셀렉트 이벤트로 팀선택 변경하는 경우
    async changeTeam () {
      try {
        // 현재 선택한 팀아이디로 팀원을 매칭한다.
        await this.getTeamStaffs(this.myTeam)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 리스트의 조직원을 클릭하면 결재자나 회람자 배열로 이동한다.
    async clickUser (staff, index) {
      try {
        if (!staff) throw new Error('error!')

        if (this.selType === 1) {
          // !! 결재자 선택인 경우 approvers 에 넣자
          this.approvers.push({
            index: this.approvers.length + 1,
            items: [ staff ]
          })
        } else {
          // !! 회람자 선택인 경우 viewers 에 넣자
          this.viewers.push(staff)
        }

        this.teamStaffs.splice(index, 1) // 팀원배열에선 제거
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: clickUser() 와 반대로 결재자(approvers) 에서 제거하기
    async rmApprover (item, index, rowIndex) {
      try {
        // teamStaffs 배열에 다시 넣기
        // this.teamStaffs.push(item)

        // approvers 에선 빼기: rowIndex 는 1부터 시작하는 row.index 임
        this.approvers[rowIndex - 1].items.splice(index, 1)

        // items 가 비었으면  this.approvers 에서 지우고 index 재지정
        if (this.approvers[rowIndex - 1].items.length === 0) {
          this.approvers.splice(rowIndex - 1, 1) // this.approvers 에서 삭제
          // !! this.approvers 의 index 를 순서대로 재지정
          let idx = 1
          for (let row of this.approvers) {
            row.index = idx
            idx++
          }
        }

        // !! 팀목록 리프레시 - 현재 선택한 팀아이디로 팀원을 매칭한다.
        this.$nextTick(async () => {
          await this.getTeamStaffs(this.myTeam)
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 회람자(viewers) 에서 제거하기
    async rmViewer (item, index) {
      try {
        // teamStaffs 배열에 다시 넣기
        // this.teamStaffs.push(item)

        // viewers 에선 빼기
        this.viewers.splice(index, 1)

        // !! 팀목록 리프레시 - 현재 선택한 팀아이디로 팀원을 매칭한다.
        this.$nextTick(async () => {
          await this.getTeamStaffs(this.myTeam)
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 바깥 approvers 의 순서를 변경하는 이벤트에 발동된다..
    async changeRows () {
      try {
        // !! this.approvers 의 index 를 순서대로 재지정
        let idx = 1
        for (let row of this.approvers) {
          row.index = idx
          idx++
        }
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
